import { BenefitAssignmentStatus, BenefitStatus } from 'store/benefits';
import { EmbeddedCustomCategory } from 'store/custom-categories';
import { ExpenseStatus } from 'store/expenses';
import { DateRange, PaginationFilters } from 'types/Common';
import { ShopItemCategory } from 'types/MarketplaceItems';
import { z } from 'zod';
import { employeesFiltersSchema } from './employees.schemas';

export const employeeStatuses = [
  'recentlyEnded',
  'ended',
  'active',
  'endsSoon',
  'inactive',
] as const;
export type EmployeeStatus = (typeof employeeStatuses)[number];

export interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  status: EmployeeStatus;
  birthday: string | null;
  isAppUser: boolean;
  hiredAt: string;
  customCategories: EmbeddedCustomCategory[];
}

export interface EmployeeWalletBenefit {
  id: string;
  name: string;
  status: BenefitStatus;
  assignmentStatus: BenefitAssignmentStatus;
  investment: number;
  expenses: number;
  remaining: number;
}

export interface EmployeeWalletRecognitions {
  totalPoints: number;
  totalCurrency: number;
  spentPoints: number;
  spentCurrency: number;
  availablePoints: number;
  availableCurrency: number;
}

export type EmployeesFilters = z.infer<typeof employeesFiltersSchema>;

export interface EmployeeExpensesFilters extends PaginationFilters {
  date: DateRange | undefined;
  source: string[];
  categories: ShopItemCategory[];
  status: ExpenseStatus[];
}

export interface EmployeeWalletBenefitsFilters extends PaginationFilters {
  benefits: string[];
  status: BenefitStatus[];
}
